import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Divider, Grid, Typography } from "@mui/material";
import Text from "../data-display/text";
import classNames from "classnames";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
//import Link from "gatsby-link";
import sydePhone from "../../images/iphone.jpg";
import quickPhone from "../../quickTaskAssets/mobilescreen.webp"
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./blogs-card.css";
import { Box } from "react-feather";
import moment from "moment";
import { environment } from "../../environments/quickTaskEnvironemt";
import { Link } from "gatsby";
import { isQuickTaskProject } from "../../utils/constant.urls";
const QuikTaskmainColor = environment.mainColor;
const iphone = isQuickTaskProject ? quickPhone : sydePhone;

const useStyles = makeStyles(() => ({
  cardTitle: {
    // position: "absolute",
    bottom: "24px",
    left: "24px",
    marginRight: "24px",
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(1,.47,.39,.82)",
  },
  root: {
    // maxWidth: 345,
    // width:  "315px",
  },
  media: {
    //  height:"500px"
  },
  cardTitleActive: {
    transform: "translateY(-32px)",
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(1,.47,.39,.82)",
  },
  viewProject: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "24px",
    left: "24px",
    marginRight: "24px",
    opacity: "0",
    transitionDuration: "0.3s",
    transitionDelay: "0s",
  },
  viewProjectActive: {
    transitionDelay: "0.2s",
    transitionDuration: "0.3s",
    opacity: "1",
  },
  card: {
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      borderColor: "rgba(0,165,234,0.1)",
      boxShadow: "none",
    },
  },
}));

const BlogsCard = ({
  title,
  smallMargin = false,
  inView = true,
  paddingTop,
  width = "calc(42% - -38.3px)",
  subtitle,
  image,
  author,
  updateAt,
  speed,
  to,
  style = {},
}: {
  style?: React.CSSProperties;
  inView?: boolean;
  title: string;
  subtitle: string;
  width?: string;
  paddingTop?: string;
  smallMargin?: boolean;
  image?: string;
  hoverimage?: string;
  speed?: string;
  featuredPhoto?: string;
  headline?: string;
  visible?: boolean;
  author?: string;
  updateAt?: Date;
  to: string;
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));

  const xPaddingTop = paddingTop ? paddingTop : xsDown ? "0%" : "0%";

  const [hover, setHover] = useState(false);
  const classes = useStyles();
  return (
    <>
      {isQuickTaskProject ? (
        <div
          className="card"
          style={{
            maxWidth: "700px",
            paddingTop: xPaddingTop,
            ...style,
            padding: "0px",
            margin: "0px",
            marginBottom: smallMargin ? "10px" : "72px",
          }}
          data-scroll={true}
          data-scroll-speed={speed}
          data-scroll-position={"top"}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Container>
            <div>
              <div
                className="container"
                style={{
                  padding: "0px",
                }}
              >
                <Link style={{textDecoration: "none", cursor: "pointer"}} to={to}>
                  <img
                    src={!image ? iphone : image}
                    alt="Avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "20px",
                      objectFit: "cover",
                    }}
                  />
                  <div />
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={8}>
                        <Grid container spacing={2} style={{paddingLeft: "10px"}}>
                          <p
                            style={{
                              background: isQuickTaskProject
                                ? QuikTaskmainColor
                                : "rgb(78, 62, 253)",
                              padding: "5px",
                              color: "white",
                            }}
                          >
                            News{" "}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Typography gutterBottom variant="h5" component="h2" style={{color: "black"}}>
                      {title?.length > 20 ? title?.slice(0, 20) : title}

                      {title?.length > 20 ? "..." : ""}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {subtitle}
                    </Typography>
                    <CardActions style={{justifyContent: "space-between"}}>
                      <div />
                      <Button size="small" style={{color: "gray"}}>
                        {moment(updateAt).format("MM-DD-YYYY")}
                      </Button>
                    </CardActions>
                  </div>

                  <div
                    className={classNames({
                      [classes.viewProject]: true,
                      [classes.viewProjectActive]: hover || xsDown,
                    })}
                  ></div>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <Link style={{textDecoration: "none", cursor: "pointer"}} to={to}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={!image ? iphone : image}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Grid container spacing={2} style={{paddingLeft: "10px"}}>
                  <p
                    style={{
                      background: isQuickTaskProject ? QuikTaskmainColor : "rgb(78, 62, 253)",
                      padding: "5px",
                      color: "white",
                    }}
                  >
                    News{" "}
                  </p>
                </Grid>

                <Typography gutterBottom variant="h5" component="h2">
                  {title?.length > 20 ? title?.slice(0, 20) : title}

                  {title?.length > 20 ? "..." : ""}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {subtitle}
                </Typography>
              </CardContent>
            </CardActionArea>
            <Divider />
            <CardActions style={{justifyContent: "space-between"}}>
              <Button size="small" style={{color: "gray"}}>
                {author}
              </Button>
              <Button size="small" style={{color: "gray"}}>
                {moment(updateAt).format("MM-DD-YYYY")}
              </Button>
            </CardActions>
          </Card>
        </Link>
      )}
    </>
  );
};

export default BlogsCard;
